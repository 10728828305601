import TomSelect from 'tom-select';

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-close-notification').forEach(el => {
        el.addEventListener('click', () => {
            let bar = el.parentElement;

            bar.style.scale = '0';

            setTimeout(() => {
                bar.remove();
            }, 100);
        });
    });

    document.querySelectorAll('.js-dropdown-link').forEach(el => {
        el.addEventListener('click', () => {
            let dropdown = document.querySelector(el.dataset.toggle);
            let dropdownIcon = el.querySelector('.dropdown-icon');

            if(dropdownIcon) {
                let original = dropdownIcon.getAttribute('src');

                dropdownIcon.setAttribute('src', dropdownIcon.dataset.alternate);
                dropdownIcon.dataset.alternate = original;
            }

            el.classList.toggle('active');

            if(dropdown.classList.contains('active')) {
                dropdown.style.scale = '0';

                setTimeout(() => {
                    dropdown.classList.toggle('active');
                }, 300);
            } else {
                dropdown.classList.toggle('active');

                setTimeout(() => {
                    dropdown.style.scale = '1';
                }, 10);
            }
        });
    });

    const trigger = document.getElementById('mobile-menu-trigger');
    const close = document.getElementById('mobile-menu-close');
    const links = document.getElementById('mobile-menu-links');

    if(trigger && close && links) {
        trigger.addEventListener('click', () => {
            links.style.display = 'flex';
    
            setTimeout(() => {
                links.style.width = '300px';
            }, 10);
        });
    
        close.addEventListener('click', () => {
            links.style.width = '0';
    
            setTimeout(() => {
                links.style.display = 'none';
            }, 300);
        });
    }

    document.querySelectorAll('.tom-select').forEach(function (element) {
        new TomSelect(element, {
            render: {
			    option: function(item, escape) {
                    console.log('option');
                    console.log(item);
                    return '<p class="margin-remove">' + item.text + '&nbsp;</p>';
                },
                item: function(item, escape) {
                    console.log('item');
                    console.log(item);
                    return '<p class="margin-remove">' + item.text + '&nbsp;</p>';
                }
            }
        });
    });

    const elements = document.querySelectorAll('.cards-nav > div > a, .cards-nav > a');

    elements.forEach(element => {
        const styles = window.getComputedStyle(element);
        if (styles.getPropertyValue('background-image') === 'none') {
            element.classList.add('no-background');
        }
    });

    window.addEventListener('resize', function () {
        handleResize();
    });
    
    function resizeDonut(el, width) {
        el.style.width = width + 'px';
        el.style.height = width + 'px';
    
        el.querySelectorAll('.center').forEach((center) => {
            center.style.width = (width - 30) + 'px';
            center.style.height = (width - 30) + 'px';
            center.style.lineHeight = (width - 30) + 'px';
        });
    
        el.querySelectorAll('.border').forEach((border) => {
            border.style.width = (width - 10) + 'px';
            border.style.height = (width - 10) + 'px';
        });
    
        el.querySelectorAll('.portion-block').forEach((portionBlock) => {
            portionBlock.style.clip = 'rect(0px, ' + width + 'px, ' + width + 'px, ' + (width / 2) + 'px)';
        });
    
        el.querySelectorAll('.circle').forEach((circle) => {
            circle.style.clip = 'rect(0px, ' + (width / 2) + 'px, ' + width + 'px, 0px)';
        });
    }

    function handleResize() {
        document.querySelectorAll('.donut-chart').forEach((el) => {
            if(el.parentElement.clientWidth < el.clientWidth) {
                resizeDonut(el, el.parentElement.clientWidth);
            }
    
            let normalWidth = el.classList.contains('donut-120') ? 120 : 150;

            if(el.clientWidth < normalWidth && el.parentElement.clientWidth > el.clientWidth) {
                if(el.parentElement.clientWidth < normalWidth) {
                    resizeDonut(el, el.parentElement.clientWidth);
                } else {
                    resizeDonut(el, normalWidth);
                }
            }
        });
    }

    handleResize();
});